<script setup lang="ts">
import { computed, defineProps, withDefaults } from 'vue'
import type { NuxtLinkProps } from '#app'

interface Props {
  outline?: boolean
  preIcon?: string
  postIcon?: string
  title?: string
  size?: 13 | 10
  theme?: 'neutral' | 'neutral-light' | 'green' | 'green-light' | 'coral' | 'blue' | 'sky' | 'warning' // theme-neutral, theme-neutral-light, theme-green, theme-green-light, theme-coral, theme-blue, theme-sky, theme-warning
  to?: NuxtLinkProps['to']
  tag?: string
  href?: string
  target?: string
  local?: boolean
  disabled?: boolean
  loading?: boolean
  block?: boolean
  square?: boolean
  notRounded?: boolean
  noInnerBorder?: boolean
  darkTheme?: string
}

const props = withDefaults(defineProps<Props>(), {
  size: 13, theme: 'coral', tag: 'button',
})

const computedTag = computed(() => {
  if (props.to) {
    return defineNuxtLink({
      activeClass: '',
      exactActiveClass: '',
    })
  }

  return props.tag || 'button'
})

// Outer classes, wrapper classes, icon size
const sizes = computed(() => ({
  10: [['h-9 lg:h-10 text-base lg:text-lg', { 'h-9 lg:w-10': props.square }], 'gap-1.5 px-2 lg:px-2', '16', {
    'px-5': !props.square,
  }],
  13: [['h-12 lg:h-13 text-lg lg:text-xl', { 'w-12 lg:w-13': props.square }], 'gap-1.5 px-3 lg:px-5', '20', {
    'px-5': !props.square,
  }],
}))

const style = computed(() => {
  return {
    button: [
      'font-accent uppercase tracking-wider p-0.5 border transition-[color,background,opacity,border] block font-accent relative font-bold',
      sizes.value[props.size]?.[0],
      {
        [`theme-${props.theme}`]: props.theme,
        [`dark:theme-${props.darkTheme}`]: props.darkTheme,
        'cursor-pointer': !props.disabled && !props.loading,
        'border-t-bg text-t-bg hover:text-t-bg-hover hover:border-t-bg-hover': props.outline,
        'border-transparent bg-t-bg hover:bg-t-bg-hover text-t-text': !props.outline,
        'rounded-xl': !props.notRounded,
        'w-full': props.block,
      },
    ],
    wrapper: [
      'flex items-center justify-center text-center h-full transition-[transform,opacity]',
      sizes.value[props.size]?.[1],
      {
        'opacity-50 cursor-not-allowed': props.disabled,
        'rounded-[0.625rem]': !props.notRounded,
        'opacity-20 scale-90': props.loading,
        'border border-t-bg border-opacity-50': props.outline && !props.noInnerBorder,
        'border border-t-border': !props.outline && !props.noInnerBorder,
      },
    ],
    customIcon: [
      'shrink-0 w-5 h-5',
    ],
    loaderWrapper: [
      'absolute left-0 top-0 right-0 bottom-0 m-auto w-5 h-5',
    ],
    loader: [
      'animate-spin w-full h-full',
    ],
  }
})
</script>

<template>
  <component :is="computedTag" :class="style.button" :to="to" :href="href" :target="target" :disabled="disabled || loading">
    <div :class="style.wrapper">
      <!-- Pre icon -->
      <template v-if="preIcon">
        <Icon v-if="!local" :name="preIcon" :size="sizes[size]?.[2]" class="shrink-0" />
        <component :is="preIcon" v-else :class="style.customIcon" />
      </template>

      <!-- Title -->
      <span v-if="title">{{ title }}</span>

      <!-- Custom content -->
      <slot />

      <!-- Post icon -->
      <template v-if="postIcon">
        <Icon v-if="!local" :name="postIcon" :size="sizes[size]?.[2]" class="shrink-0" />
        <component :is="postIcon" v-else :class="style.customIcon" />
      </template>
    </div>
    <Transition name="fade-scale-from-80">
      <div v-if="loading" :class="style.loaderWrapper">
        <IconsLogoSmall :class="style.loader" />
      </div>
    </Transition>
  </component>
</template>
